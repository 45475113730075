import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CustomReuseStrategy } from 'src/app/custom-reuse-strategy';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private router: Router, private routeReuseStrategy: CustomReuseStrategy, private cookieService: CookieService) { }
   private username: string = '';
   public finalReportData:any = {};
   public vehiclaValidation:any ={};
   public towingUnitData:any = {};
   public selectedMarker:any = {};
   public wireframeData:any = {};
   private _ssoId: string = '';
   private _isInspectionComplete: boolean = false;
   private resetData = new BehaviorSubject<any>(null);
   resetData$ = this.resetData.asObservable();
   private _manualRAObjectData: any = {};
   private _raByUnitData: any = {};
   public svgList:any={};
   errorPage: boolean = false;


   setDeviceLocationId(deviceLocationId: string): void {
      localStorage.setItem('deviceLocationId', deviceLocationId);
    }
    private _pdfData: any = {
      id:'',
      link:''
     };

    getDeviceLocationId(): string | null {
      return localStorage.getItem('deviceLocationId');
    }

    getDateFormatted(date?: Date): string {
      const today = date ? new Date(date) : new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      return `${month}/${day}/${year}`;
    }

    clearHomeRouteCache() {
      this.routeReuseStrategy.clearStoredRoute('home');
      this.router.navigate(['home']);
    }

    setUsername(username: string) {
      this.username = username;
    }

    getUsername(): string {
      return this.username;
    }

    getSsoId(): string {
      return this._ssoId;
    }

    setSsoId(value: string) {
      this._ssoId = value;
    }

    setCookie(name: string, value: string, days: number): void {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      this.cookieService.set(name, value, { expires });
    }

    deleteCookie(name: string): void {
      this.cookieService.delete(name);
    }

    getCookie(name: string): string | null {
      return this.cookieService.get(name);
    }

    checkHighContrast(): boolean {
      const username = this.getUsername();
      if (username) {
        const highContrastCookie = this.getCookie(`highContrast${username}`);
        return highContrastCookie === 'true';
      }
      return false;
    }

    setFinalReportData(data:any){
      this.finalReportData = data;
    }

    getFinalReportData(){
      return this.finalReportData;
    }
    setTowingUnitData(data:any){
      this.towingUnitData = data;
    }
    getTowingUnitData(){
      return this.towingUnitData;
    }

    setWireframeData(data:any){
      this.wireframeData = data;
    }

    getWireframeData(){
      return this.wireframeData;
    }

    public get pdfData(): any {
      return this._pdfData;
    }

    public set pdfData(value: any) {
      this._pdfData = value;
    }

    handleResetData(val:any){
      this.resetData.next(val);
    }

    newRaObject(inspectionType:string) {
      let newRa:any = {};
      newRa.customerPresent = true;
      newRa.unitNumber = null;
      newRa.inspectionType = inspectionType;
      newRa.raNumber = null;
      newRa.isRaManualEntry = false;
      newRa.vehicleInfo = {};
      newRa.signingCustomer = null;
      newRa.secondaryUnitNumber = null;
      newRa.dateOut = null;
      newRa.dateIn = null;
      newRa.isVehicleAtExpectedLocation = true;
      newRa.customerName = null;
      newRa.vehicleInfo = {};
      newRa.emailAddress = null;
      newRa.mileageIn = null;
      newRa.mileageOut = null;
      newRa.handtrucks = null;
      newRa.handtrucksReturned = null;
      newRa.furniturePads = null;
      newRa.furniturePadsReturned = null;
      newRa.reeferStandbyHours = null;
      newRa.reeferRunningHours = null;
      newRa.transactionId = null;
      newRa.billGroup = null;
      newRa.customerDistrict = null;
      newRa.damageList = {};
      return newRa;
  }

    manualRAObject(){
      let manualRA:any = {};
      manualRA.emailAddress = null;
      manualRA.customerPresent = false;
      manualRA.unitNumber = null;
      manualRA.inspectionType = null;
      manualRA.raNumber = null;
      manualRA.signingCustomer = null;
      manualRA.secondaryUnitNumber = null;
      manualRA.vehicleValidation = {};
      return manualRA;
    }

    public get manualRAObjectData(): any {
      return this._manualRAObjectData;
    }
    public set manualRAObjectData(value: any) {
      this._manualRAObjectData = value;
    }

    public get raByUnitData(): any {
      return this._raByUnitData;
    }
    public set raByUnitData(value: any) {
      this._raByUnitData = value;
    }

    public get isInspectionComplete(): boolean {
      return this._isInspectionComplete;
    }
    public set isInspectionComplete(value: boolean) {
      this._isInspectionComplete = value;
    }

    setWireframeSvgs(wirefreamId:number,svg:any){
      if(!this.svgList[wirefreamId]){
        this.svgList[wirefreamId] = '';
      }
      this.svgList[wirefreamId] = svg;
    }

    getWireframeSvgs(){
      return this.svgList;
    }
}
