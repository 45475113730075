import { Component, ChangeDetectorRef } from '@angular/core';
import { RouteService } from '../../services/route/route.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakService } from 'keycloak-angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isHomeRoute: boolean = true;
  version: string = '';
  userName: string = '';

  // Include all relevant Keycloak-related cookies

  constructor(private location:Location,private routeService: RouteService, private router: Router, private cdr: ChangeDetectorRef, private commonService: CommonService, private cookieService: CookieService, private keycloakService: KeycloakService) {} // Inject the service

  ngOnInit() {
    this.version = `Inspection Version ${environment?.version}`;
    this.userName = this.commonService.getUsername();
  }

  goToHome() {
    if(this.location.path() == "/home"){
      this.commonService.handleResetData(true);
    }else{
    this.commonService.clearHomeRouteCache();
  }
}

  logout() {
     this.keycloakService.logout(environment.ssologout_url);
  }

}
