<p-dialog *ngIf="showErrorInfoMessage" header="500 Internal Server Error" [modal]="true" [visible]="true"
    [style]="{ 'width': '30rem', 'min-height':'12rem' }" (visibleChange)="closeErrorInfoModal()">
    <div class="row mt-4">
        <div class="col-12">
            <div>Looks like our servers hit a bump in the road. We're woriking on it and will be back on track shortly
            </div>
            <img src="assets/500Error.svg" class="me-2 error500Img"><br>
            <span>What can you do?.</span>
            <ul>
                <li>Refresh the page.</li>
                <li>Try again in a few minutes.</li>
                <li>Contact Support if the issue persist.</li>
            </ul>
        </div>
    </div>
    <p-footer>
        <button class="btn outlineBtnSecondary pull-right" (click)="closeErrorInfoModal()">Close</button>
    </p-footer>
</p-dialog>