import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  apiError = new BehaviorSubject<HttpErrorResponse | null>(null);
  apiError$ = this.apiError.asObservable();
  constructor() { 
  }
}
