import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private apiUrl = environment.backend_url;
  constructor(private http: HttpClient) { }

  saveReport(payload:any): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/inspection/savereport`, payload);
  }

  signReport(payload:any):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/inspection/signreport`, payload);
  }
  sendEmail(userEmails:any):Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/inspection/emailreport`, userEmails);
  }
  downloadPdf(inspectionId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/history/pdf/${inspectionId}`,{responseType:'blob'});
  }

  handlePdfResponse(pdfRes:any){
    const pdfBlob = new Blob([pdfRes],{type:'application/pdf'});
    return window.URL.createObjectURL(pdfBlob);
  }

}
