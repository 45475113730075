import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private homeRouteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  setHomeRoute(isHomeRoute: boolean): void {
    this.homeRouteSubject.next(isHomeRoute);
  }

  getHomeRoute(): Observable<boolean> {
    return this.homeRouteSubject.asObservable();
  }
}
