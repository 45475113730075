import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InspectionDetailsService } from '../../services/inspection-details/inspection-details.service';
import { CommonService } from '../../services/common/common.service';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-svg',
  standalone: true,
  imports:[LoaderComponent, CommonModule],
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  @Input() public src = '';
  @Input() public isMobile: boolean = false;
  @Input() public wireframeId: any = null;
  @Output() onWireFrameLoad = new EventEmitter<any>();
  @Output() onWireFrameClick = new EventEmitter<any>();
  showLoader: boolean = false;
  constructor(
    private el: ElementRef,
    private inspDetailsService: InspectionDetailsService,
    private commonService: CommonService
) {}

ngOnInit(): void {
    if(this.commonService.getWireframeSvgs()?.[this.wireframeId]){
      this.handleWireframeSvg(this.commonService.getWireframeSvgs()[this.wireframeId]);
      return;
    }
    this.showLoader = true;
    this.inspDetailsService.getWireFrame(this.wireframeId).subscribe({
      next:(svg: any) => {
        this.showLoader = false;
        this.commonService.setWireframeSvgs(this.wireframeId,svg);
        this.handleWireframeSvg(svg);
      },error: (error: any) => {
        this.showLoader = false;
        console.error('Error fetching wireframe image data', error);
      }
    });
}

handleWireframeSvg(svg:any){
  this.el.nativeElement.innerHTML = svg;
  this.onWireFrameLoad.emit({wireframeId: this.wireframeId, svg: svg});
}

wireFrameClick(event:MouseEvent) {

}

}
