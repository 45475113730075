import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../../services/history/history.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PdfService } from '../../services/pdf/pdf.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { TableModule } from 'primeng/table';


@Component({
  selector: 'app-inspection-history',
  standalone: true,
  imports:[CommonModule, FormsModule, LoaderComponent, TableModule],
  templateUrl: './inspection-history.component.html',
  styleUrls: ['./inspection-history.component.scss']
})
export class InspectionHistoryComponent implements OnInit {
  apiUrl = environment.backend_url;
  historyData: any = [];
  cols: any = [
    { field: 'raNumber', header: 'RA#' },
    { field: 'inspectionTypeStringFormatted', header: 'Type' },
    { field: 'createdOn', header: 'Date' },
    { field: '', header: 'Report Link' }
  ];
  unitNumber!: any;
  showLoader: boolean = false;

  constructor(private historyService: HistoryService, private route: ActivatedRoute, private pdfService: PdfService) {
    this.unitNumber = this.route.snapshot.paramMap.get('id') != null ? this.route.snapshot.paramMap.get('id') : '';
  }

  ngOnInit() {
    this.showLoader = true;
    this.historyService.getHistoryData(this.unitNumber).subscribe(resp => {
      if (resp && resp.success) {
        this.historyData = resp.data;
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    }, (error: any) => {
      console.error('Error fetching history data', error);
      this.showLoader = false;
    });
  }

  downloadPdf(inspectionId:string){
    this.showLoader = true;
    this.pdfService.downloadPdf(inspectionId).subscribe({
      next:(res:any)=>{
        window.open(this.pdfService.handlePdfResponse(res));
        this.showLoader = false;
      },error:(e)=>{
        console.log('Error occured while fetching the pdf :',e);
        this.showLoader = false;
      }
    })
  }

}
