<div class="content">
    <h4 class="col-md-12 col-xs-12 col-lg -12 col-sm-12 d-flex justify-content-center heading-background p-2">Existing
        Reports for Unit {{unitNumber}}</h4>
    <p-table [columns]="cols" [value]="historyData"  [paginator]="true" [rows]="20" [rowsPerPageOptions]="[20, 50, 100]">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span *ngIf="col.field != 'createdOn'">{{ rowData[col.field] }}</span>
                    <span *ngIf="col.field == 'createdOn'">{{ rowData[col.field] | date:'MM/dd/YYYY' }}</span>
                    <span *ngIf="col.header == 'Report Link'"><nav class="reportLink" (click)="downloadPdf(rowData.inspectionId)">PDF<i class="pi pi-download"></i></nav></span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-loader *ngIf="showLoader"></app-loader>
