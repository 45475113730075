import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private screenWidth$: BehaviorSubject<number>;

  constructor() {
    this.screenWidth$ = new BehaviorSubject(window.innerWidth);
    window.addEventListener('resize', () => {
      this.screenWidth$.next(window.innerWidth);
    });
  }

  isTabletOrBigger(): Observable<boolean> {
    return this.screenWidth$.pipe(map(width => width >= 768));
  }
}
