import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import Hammer from 'hammerjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-camera-overlay',
  standalone: true,
  imports:[CommonModule, FormsModule, ConfirmDialogModule, ButtonModule],
  providers:[ConfirmationService, MessageService],
  templateUrl: './camera-overlay.component.html',
  styleUrls: ['./camera-overlay.component.scss']
})
export class CameraOverlayComponent implements OnInit ,AfterViewInit, OnDestroy{
  cameraContainer!: any;
  video!: any;
  overlay!: any;
  photoCanvas!: any;
  overlayContext: any;
  photoContext: any;
  svg!: string;
  zoomLevel: number = 1;  // Initial zoom level
  imgResolutionHigh: boolean = false;
  showRefreshBtn:boolean = false;
  isCameraClosed:boolean = true;
  @Input() inspectionCardDtls: any = '';
  @Input() selectedDamage: any = '';
  @Output() afterPhotoCaptured = new EventEmitter<any>();

  stream: MediaStream | undefined;
  maxZoom!:number;
  minZoom!:number;
  hardwareZoomTimeout:any
  checkCameraFeedTimer:any
  isCameraStoppedWorking:boolean = false;
  @ViewChild('videoElement', { static:true }) videoElement: ElementRef | undefined;
  @ViewChild('canvasElement', { static:true }) canvasElement: ElementRef | undefined; 
  @ViewChild('cameraModal',{ static: false }) cameraModal!: ElementRef;
  constructor(private confirmationService: ConfirmationService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.openCameraButton();
    this.preventZoomOnDoubleTap();
    this.initializePinchZoom();
    this.preventDefaultMobileZoom();
    
  }

  preventZoomOnDoubleTap(){
    const buttons:any = document.getElementsByClassName('zoom-btn');
    Array.from(buttons).forEach((button:any)=>{
      button.addEventListener('touchstart',(e:any)=>{
        if(e.touches.length>1){
          e.preventDefault();
        }
      },{passive: false});
    })
  }

  preventDefaultMobileZoom(){
    document.addEventListener('touchmove', (e)=>{
      if((e as any).scale !== undefined && (e as any).scale !== 1){
        e.preventDefault(); // Prevents default browser zoom
      }
    },{passive:false});
  }

  initializePinchZoom(){
    if(this.videoElement){
      const videoElement = this.videoElement.nativeElement;
      const hammer:any = new Hammer(videoElement);
      hammer.get('pinch').set({enable: true});
      hammer.on('pinch',(event:any)=>{
        const deltaScale = event.scale - 1;
        this.adjustZoom(deltaScale*0.5);
      })
    }
  }

  applyCSSZoom(){
    if(this.videoElement){
      const videoElement = this.videoElement.nativeElement;

      //Normalize the css zoom level to avoid execessive scaling
      //Example: If zoomLevel is between 1 and 3, map it linearly to css scale.
      const cssScale = 1 + ((this.zoomLevel-1)/(this.maxZoom-this.minZoom));

      videoElement.style.transform = `scale(${cssScale})`; //Applying normalized css scale
      videoElement.style.transition = `transform 0.2s ease`; //for smooth transition
    }
  }

  applyHardwareZoom(){
    if(this.stream){
      const videoTrack = this.stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities() as any;

      if('zoom' in capabilities){
        const constraints:any = { zoom: this.zoomLevel};
        videoTrack.applyConstraints(constraints)
                    .then(()=>console.log('Hardware zoom applied'))
                    .catch(error=>console.log('Error while applying hardware zoom ',error));
      }else{
        console.log('zoom is not supported on this device.');
      }
    }
  }

  adjustZoom(delta:number){
    if(this.stream){
      const videoTrack = this.stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities() as any;

      if('zoom' in capabilities){
        const zoomMin = capabilities.zoom.min || this.minZoom;
        const zoomMax = capabilities.zoom.max || this.maxZoom;

        this.zoomLevel = Math.min(Math.max(this.zoomLevel + delta,zoomMin),zoomMax);

        this.applyCSSZoom();

        // Throttle hardware zoom application to sync with css zoom after a delay
        clearTimeout(this.hardwareZoomTimeout);
        this.hardwareZoomTimeout = setTimeout(()=>{
          this.applyHardwareZoom();
        },200)
      }else{
        console.log('zoom is not supported on this device.');        
      }
    }
  }

  openCameraButton() {
    this.svg = this.selectedDamage == 'standardPhoto' ? this.inspectionCardDtls?.svgData : '';
    this.cameraContainer = document.getElementById('camera-container');
    this.cameraContainer.style.display = 'flex';
    this.startCamera();
  }


  async startCamera() {
    try {
      const constraints:any = {
        video: { 
          facingMode: {ideal : 'environment'},
          width: { 
            ideal: this.imgResolutionHigh ? 975 : 422,
            min: this.imgResolutionHigh ? 640 : undefined,
            max: this.imgResolutionHigh ? 975 : 422,
          },
          height: { 
            ideal: this.imgResolutionHigh ? 975 : 422,
            min: this.imgResolutionHigh ? 480 : undefined,
            max: this.imgResolutionHigh ? 975 : 422,
          },
        }
      }
      
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.overlay = document.getElementById('overlay');
      if(this.videoElement){
        this.videoElement.nativeElement.srcObject = this.stream;
        this.showRefreshBtn = false;
        this.isCameraClosed = false;
      }

      this.applyZoomConstraints();
      this.resetZoom();
    } catch (error:any) {
      console.error('Error accessing the camera', error);
      if (error?.name === 'NotReadableError') {
        this.showRefreshBtn = true;
        alert('The camera is currently in use by another application or tab. Please close other applications using the camera and try again.');
      }
      if(this.imgResolutionHigh){
        console.log('High Resolution failed, falling back to default resolution.');
        this.imgResolutionHigh = false;
        this.startCamera();
      }
    }
  }
  
  beginCheckingForCameraGlitch(){
    // Check the camera feed periodically, but avoid unnecessary recursive calls
    this.checkCameraFeedTimer = setInterval(() => {
      if (this.videoElement && this.videoElement.nativeElement.srcObject) {
        const videoTracks = this.videoElement.nativeElement.srcObject.getVideoTracks();
        if ((videoTracks.length === 0 || videoTracks[0].readyState === 'ended')) {
          this.cameraGlitchAlert('');
        }
      }
      console.log('Checking camera status...');
    }, 1000);
  }

  cameraGlitchAlert(event:any){
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Camera stopped working. Please restart',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        clearInterval(this.checkCameraFeedTimer);
        this.startCamera();
        this.beginCheckingForCameraGlitch();
      },
      reject: () => {
        clearInterval(this.checkCameraFeedTimer);
        this.closeCamera();
      }
    });
  }

  handleAlertClose(event:any){
    clearInterval(this.checkCameraFeedTimer);
    this.startCamera();
    this.isCameraStoppedWorking = false;
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.cameraModal.nativeElement, 'shown.bs.modal', () => {
      this.beginCheckingForCameraGlitch();
      this?.openCameraButton();
    });
    if(this.videoElement){
      this.videoElement.nativeElement.addEventListener('loadedmetadata', () => {
        const videoWidth = this.videoElement?.nativeElement.videoWidth;
        const videoHeight = this.videoElement?.nativeElement.videoHeight;

        this.overlay.width = videoWidth;
        this.overlay.height = videoHeight;
        this.drawOverlay(this.svg);
      });
    }
  }

  applyZoomConstraints(){
    if(this.stream){
      const videoTrack = this.stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities() as any;

      if('zoom' in capabilities){ // check if zoom is supported
        const zoomRange = [capabilities.zoom.min, capabilities.zoom.max]; // get zoom range
        const zoomValue = Math.min(Math.max(1.5, zoomRange[0]), zoomRange[1]); //set zoom within range

        const constraints:any = {
          zoom: zoomValue
        }

        videoTrack.applyConstraints(constraints).then(()=>{
          console.log('zoom applied : ',zoomValue);
        }).catch((error)=>{
          console.log('Error applying zoom constraints : ',error);
        })
      }else{
        console.log('zoom is not supported on this device.');        
      }
    }
  }

  drawOverlay(svg: string) {
    const img = new Image();
    img.src = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
    img.onload = () => {
      const overlayContext: any = this.overlay.getContext('2d');
      overlayContext.drawImage(img, 0, 0, this.overlay.width, this.overlay.height);
    };
  }

  zoomIn() {
    this.adjustZoom(0.2);
  }

  zoomOut() {
    this.adjustZoom(-0.2);
  }

  resetZoom() {
    if(this.stream){
      const videoTrack = this.stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities() as any;

      //set the zoom level to the minium zoom available
      this.zoomLevel = capabilities.zoom ? capabilities.zoom.min || 1 : 1;

      const constraints:any = {zoom: this.zoomLevel}
      
      videoTrack.applyConstraints(constraints)
      .then(()=>console.log('zoom reset to default'))
      .catch((error)=>console.log('Error reseting zoom: ',error))
    }
  }

  stopCamera(){
    if(this.stream){
      const tracks = this.stream.getTracks();
      tracks.forEach(track=>track.stop()); // stop all tracks to stop camera feed.
    }
  }

  closeCamera(){
    this.resetZoom();
    this.imgResolutionHigh = false;
    clearInterval(this.checkCameraFeedTimer);
    this.isCameraClosed = true;
    let modalButton = document.getElementById('modalCloseButton');
    if (modalButton) modalButton.click();
  }

  updateZoom() {
    this.video.style.transform = `scale(${this.zoomLevel})`;
  }

  toggleImageResolution(){
    this.imgResolutionHigh = !this.imgResolutionHigh;
    if(this.stream){
      this.stopCamera();

      this.startCamera().then(()=>{
        console.log('Camera started successfully.');
      }).catch(error=>{
        console.log('Error restarting the camera.');

        this.imgResolutionHigh = false; // Falling back to default resolution.
        this.startCamera();
      })
    }
  }

  capturePhoto() {
    if(this.canvasElement && this.videoElement){
      const canvas = this.canvasElement.nativeElement;
      const video = this.videoElement.nativeElement;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imgFormat = 'image/jpeg';
      const photoDataUrl = canvas.toDataURL(imgFormat);

      if(photoDataUrl.indexOf(';base64,')==-1){
        this.handleNullImage();
        return;
      }

      this.afterPhotoCaptured.emit({ image: photoDataUrl, wireframeId: this.inspectionCardDtls.wireframeId });
      clearInterval(this.checkCameraFeedTimer);
      this.closeCamera();
      this.imgResolutionHigh = false;
    }
  }

  handleNullImage(){
    this.confirmationService.confirm({
      message: "We couldn't capture the image. Please ensure the camera is available and try again.",
      header: 'Error',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.closeCamera();
      },
      reject: () => {
        clearInterval(this.checkCameraFeedTimer);
        this.closeCamera();
      }
    });
  }

  // Use this method to check the size and format of the image!
  checkQuality(photoDataUrl:any){
    const byteString = atob(photoDataUrl.split(',')[1]);
    const mimeString = photoDataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ab[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    console.log('Image size in KB and type:', blob.size / 1024, blob.type);
  }


  detectOS() {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check for Android or iOS
    if (/android|iphone|ipad|ipod/.test(userAgent)) {
        return true;
    }

    // Return false for Windows or Mac
    if (/windows|macintosh/.test(userAgent)) {
        return false;
    }

    // Return false for other platforms
    return false;
  }

  stopCameraManually(){
    // Use this method to stop the camera manually.
        if (this.videoElement && this.stream) {
          console.log('Tab is backgrounded, stopping video stream');
          this.stream.getTracks().forEach(track => track.stop());
          this.stopCamera(); // Cleanup and stop the camera.
        }
    }

    ngOnDestroy(): void {
      this.closeCamera();
      this.stopCamera();
    }
}
