import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import SignaturePad from 'signature_pad';
import { PdfService } from '../../services/pdf/pdf.service';
import { CommonService } from '../../services/common/common.service';
import { LoaderComponent } from '../loader/loader.component';
import { AgreementComponent } from '../agreement/agreement.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-signature',
  standalone: true,
  imports: [LoaderComponent, AgreementComponent, CommonModule],
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit{
  public showLoader:boolean = false;
  pdfLink:string= ''
  signatureNeeded: boolean = true;
  signaturePad!: SignaturePad;
  isUserAccepted:boolean=false;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  constructor(
    private router: Router,
    private commonService:CommonService,
    private pdfService: PdfService
  ){}
  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
      minWidth: 1,
      maxWidth: 2,
    });
     this.drawDefaultLine();
    this.signaturePad.addEventListener("endStroke", () => {
      this.signatureNeeded = false;
    });
  }

  drawDefaultLine() {
    const canvas = this.canvasEl.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = '#000';
    ctx.lineWidth = 2;

    const startX = 20;
    const startY = canvas.height-10;
    const endX = canvas.width-20;
    const endY = canvas.height-10;
    console.log(endX,endY)

    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
}

  onBack(){
    this.router.navigate(['/summary']);
  }

  clearPad() {
    this.signatureNeeded = true;
    this.signaturePad.clear();
    this.drawDefaultLine();
  }

  onAccept() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data.split(",")[1];
    this.showLoader = true;
    this.pdfService.signReport({
      reportMasterId : this.commonService.pdfData.id,
      signaturePic: this.signatureImg
    }).subscribe({
      next:()=>{
        this.pdfService.downloadPdf(this.commonService.pdfData.id).subscribe({
          next:(res:any)=>{
            this.pdfLink = this.pdfService.handlePdfResponse(res);
            this.isUserAccepted = true;
            this.commonService.pdfData = {
              id: this.commonService.pdfData.id,
              link: this.pdfLink
            };
            this.showLoader = false;
            this.router.navigate(['signature']);
          },error:(e)=>{
            console.log('Error occured while fetching the pdf :',e);
            this.showLoader = false;
          }
        }) 
      },error:()=>{
        this.showLoader = false;
      }
    })
  }

  getSignWidth(){
    return window.matchMedia("(min-width: 768px)").matches ? 400:360;
  }
}
