import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { saveReport } from '../../interfaces/savereport.model';

@Injectable({
  providedIn: 'root'
})
export class InspectionDetailsService {
  private apiUrl = environment.backend_url;
  constructor(private http: HttpClient) { }

  getWireFrame(wireframeId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this.http.get<any>(`${this.apiUrl}/image/wireframe/${wireframeId}`, { headers: headers, responseType: 'image/svg+xml' as 'json' }); 
  }

  saveReport(reportData: saveReport) {
    return this.http.post<any>(`${this.apiUrl}/inspection/savereport`, reportData);
  }

  saveImages(imageData: any, vehicleId: any) {
    let formData = new FormData();
    formData.append('file', this.base64ImageToBlob(imageData));
    return this.http.post<any>(`${this.apiUrl}/inspection/imageupload/${vehicleId}`, formData);
  }


  getImageById(imageId: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/image/damage/${imageId}`, { responseType: 'blob' });
  }
  
  base64ImageToBlob(str: any) {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
  
    return blob;
  }
}
