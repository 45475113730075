import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../services/error/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [DialogModule, CommonModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent implements OnInit {
  showErrorInfoMessage: boolean = false;
  errorRoutes: any = {
    401: '/error401',
    403: '/error403',
    404: '/error404'
  };

 

  constructor(private errorService: ErrorService, private router: Router, private commonService: CommonService){
  }

  ngOnInit(): void {
    this.getReadAPIError();
  }

  async getReadAPIError() {
    this.errorService.apiError.subscribe((error: any) => {
      if (error) {
        if (error.status === 500) {
          this.showErrorInfoMessage = true;
        }
        if (this.errorRoutes[error.status]) {
          this.commonService.errorPage = true;
          this.router.navigate([this.errorRoutes[error.status]]);
        }
      }
      else {
        this.showErrorInfoMessage = false;
      }
    })
  }

  closeErrorInfoModal(){
    this.errorService.apiError.next(null);
  }

}
