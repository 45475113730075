<div class="signature mx-auto" *ngIf="!isUserAccepted">
    <div class="signature-container p-md-4 mt-md-3">
        <div class="signature-header p-4 text-left">
            <h2 class="blue-color">Rental Inspection Report</h2>
            <span>Completed on 05/22/2024</span>
        </div>
        <span class="fw-medium p-4">Capture Signature </span>
        <div class="p-3 shadow">
            <canvas #canvas id="signCanvas" height="189" [width]="getSignWidth()"></canvas>
        </div>
        <div class="agreement-footer p-4 shadow">
            <h6 class="blue-color mb-4">Signature Controls</h6>
            <div class="signature_btns d-flex justify-content-between">
                <button class="btn fw-medium" (click)="onBack()">Edit Report</button>
                <button class="btn fw-medium" (click)="clearPad()">Clear</button>
                <button class="btn btn-yellow fw-medium border-0" [disabled]="signatureNeeded" (click)="onAccept()">Accept</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isUserAccepted">
    <app-agreement [isUserSignedAndAccepted]="isUserAccepted"></app-agreement>
</div>

<app-loader *ngIf="showLoader"></app-loader>
