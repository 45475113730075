import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-error403',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error403.component.html',
  styleUrl: './error403.component.scss'
})
export class Error403Component {

}
