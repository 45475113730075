import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InspectionDetailsService } from '../../services/inspection-details/inspection-details.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { ScreenSizeService } from '../../services/screen-size/screen-size.service';
import { InspectionUtilsService } from '../../services/inspection-utils/inspection-utils.service';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../svg/svg.component';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { ImageModule } from 'primeng/image';
import { CheckboxModule } from 'primeng/checkbox';
import { InspectionDetailsMobileComponent } from '../inspection-details-mobile/inspection-details-mobile.component';
import { CameraOverlayComponent } from '../camera-overlay/camera-overlay.component';
import { LoaderComponent } from '../loader/loader.component';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AlertComponent } from '../alert/alert.component';


@Component({
  selector: 'app-inpection-details-new',
  standalone: true,
  imports:[CommonModule, CarouselModule, FormsModule, SvgComponent, ButtonModule, BadgeModule, AlertComponent, ImageModule, CheckboxModule, InspectionDetailsMobileComponent, CameraOverlayComponent, LoaderComponent, DialogModule, RadioButtonModule],
  templateUrl: './inpection-details-new.component.html',
  styleUrls: ['./inpection-details-new.component.scss']
})
export class InpectionDetailsNewComponent implements OnInit{
  damageList:any = [];
  vehicleWireframeData:any
  showLoader:boolean=false;
  isTabletOrBigger$:any;
  markerCounter:number=0;
  noStandardImage:boolean = false;
  selectedDamage!:string;
  damageNoType:boolean=false;
  disableWalkAroundBtn:boolean=false;
  damageType:string='';
  disableStandardPhoto:boolean=false;
  updateImgSrc:boolean = false;
  majorDamage:any = false;
  showComment:any = false;
  currentPage = 0;
  @ViewChild('detailsPanel') detailsPanel!: ElementRef;
  @ViewChild('wireframeSvg') vehicleImage!: ElementRef<HTMLImageElement>;
  nextArrowvisible: boolean = false;
  backArrowVisible: boolean= true;
  constructor(private router: Router,
    public commonService: CommonService, private screenSizeService: ScreenSizeService,
    private sanitizer: DomSanitizer, public inspectionUtilsService: InspectionUtilsService
  ) {
  }

  ngOnInit(): void {
    this.inspectionUtilsService.selectedInspectionCardDetails = {};
    this.screenSizeService.isTabletOrBigger().subscribe({
      next:isTablet=>{
        this.isTabletOrBigger$ = isTablet;
      }
    });

    if(this.inspectionUtilsService.getCheckOutDamageList().length){
      this.damageList = this.inspectionUtilsService.getCheckOutDamageList();
    }else if(this.commonService.getFinalReportData().inspectionType == 'IN'){
      this.damageList = this.inspectionUtilsService.latestReportData?.vehicles[0].damages;
    }
    console.log('damge lst : ',this.damageList);
    this.handleInspectionData();
  }

  handleInspectionData(){
    let newDamageObj:any = {};
    if(this.damageList?.length && (!Object.keys(this.inspectionUtilsService.getDamageMarkers()).length && !Object.keys(this.inspectionUtilsService.getStandardMarkers()).length)){
      this.damageList.forEach((damage:any)=>{
          if(!newDamageObj[damage.wireframeId]){
            newDamageObj[damage.wireframeId] = [];
          }
          newDamageObj[damage.wireframeId].push(damage);
          damage.isStandard ? this.inspectionUtilsService.setStandardMarkers(damage.wireframeId, damage) : this.inspectionUtilsService.setDamageMarkers(damage.wireframeId, damage);
      })
    }
    if(this.inspectionUtilsService.vehicleWireframeData.length){
      this.vehicleWireframeData = this.inspectionUtilsService.vehicleWireframeData;
    }else{
      this.vehicleWireframeData = this.inspectionUtilsService.vehicleData.wireframeGroup?.map((list:any,i:number)=>{
        return {
                number: i+1,
                title: list.locationDescription, 
                damage: { OUT: 'No', IN: 'No' },
                comments: { OUT: 'No', IN: 'No' },
                stdPhoto: { OUT: 'No', IN: 'No' },
                wireframeId: list.wireframeId,
                svgData: '',
                active:false
              }
      })
      this.inspectionUtilsService.vehicleWireframeData = this.vehicleWireframeData;
    }
  }

  handleDamageListImageIdsWhenEdit(){
    if((Object.keys(this.inspectionUtilsService.getDamageMarkers()).length || Object.keys(this.inspectionUtilsService.getStandardMarkers()).length)){
      this.damageList.forEach((listItem:any)=>{
        if(listItem.isStandard){
          let standardMarkerFound = this.inspectionUtilsService.getStandardMarkers()[listItem.wireframeId].find((marker:any)=>marker.id === listItem.id);
          standardMarkerFound.imageId = listItem.imageId
        }else{
          let damageMarkerFound = this.inspectionUtilsService.getDamageMarkers()[listItem.wireframeId].find((marker:any)=>marker.id === listItem.id);
          damageMarkerFound.imageId = listItem.imageId
        }
      })
    }
  }

  onCardClick(cardDetails:any){
    this.nextArrowvisible = false;
    if(this.vehicleWireframeData[0].number == cardDetails.number){
      this.backArrowVisible = false;
    }else if(this.vehicleWireframeData[this.vehicleWireframeData.length-1].number == cardDetails.number){
      this.nextArrowvisible = true;
      this.backArrowVisible = true
    }else{this.backArrowVisible = true;}
    this.inspectionUtilsService.selectedMarker = {};
    this.resetActiveCard();
    this.inspectionUtilsService.selectedInspectionCardDetails = cardDetails;
    this.inspectionUtilsService.selectedInspectionCardDetails.active = true;
    setTimeout(() => {
      if (this.detailsPanel) {
        this.detailsPanel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
    this.loadSelectedWireFrame(cardDetails.wireframeId);
    this.reportTypes('standardPhoto');
    this.handleMarkers();
    this.showComment = !!this.inspectionUtilsService?.selectedMarker?.comments;
    this.inspectionUtilsService.reValidateCardDetails(this.commonService.getFinalReportData().inspectionType);
    this.inspectionUtilsService.handleUploadingImages();
  }

  reportTypes(photoType: string) {
    this.inspectionUtilsService.selectedMarker = {};
    this.selectedDamage = photoType;
    this.inspectionUtilsService.currentFrameMarkerCounter = this.selectedDamage == 'standardPhoto' ? this.getCurrStandardMarkers().length :this.getCurrDamageMarkers().length;
    if(this.selectedDamage == 'standardPhoto' && this.getCurrStandardMarkers()?.length){
      this.updateToSelectedMarker(this.getCurrStandardMarkers()[this.inspectionUtilsService.currentFrameMarkerCounter-1]);
    }else if(this.selectedDamage == 'damagePhoto' && this.getCurrDamageMarkers()?.length){
      this.updateToSelectedMarker(this.getCurrDamageMarkers()[this.inspectionUtilsService.currentFrameMarkerCounter-1]);
    }
  }

  handleMarkers(){
    if (this.selectedDamage == 'standardPhoto') {
      if(this.getCurrStandardMarkers().length){
        this.updateToSelectedMarker(this.getCurrStandardMarkers()[this.getCurrStandardMarkers().length-1]);
      }
      this.inspectionUtilsService.currentFrameMarkerCounter = this.getCurrStandardMarkers().length;
    }else{
      if(this.getCurrDamageMarkers().length){
        this.updateToSelectedMarker(this.getCurrDamageMarkers()[this.getCurrDamageMarkers().length-1]);
      }
      this.inspectionUtilsService.currentFrameMarkerCounter = this.getCurrDamageMarkers().length;
    }
  }

  loadSelectedWireFrame(wireframeId: number) {
    let svg = this.commonService.getWireframeSvgs()?.[wireframeId];
    this.inspectionUtilsService.wireFrameImg = this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  wireFrameClick(event:any){
    const target = event.target as HTMLElement;
    if(this.selectedDamage !== 'damagePhoto'){
      if (target?.id.startsWith('standard-marker-')) {
        const markerFound = this.getCurrStandardMarkers().find((marker:any) => marker.id === target.id);
        if (markerFound) {
          this.updateToSelectedMarker(markerFound);
          this.inspectionUtilsService.currentFrameMarkerCounter = markerFound.number;
        }
      }
    }else{
      if (target?.id.startsWith('marker-')) {
        if(this.damageNoType || this.disableWalkAroundBtn)
          return;
        const markerFound = this.getCurrDamageMarkers().find((marker:any) => marker.id === target.id);
        if (markerFound) {
          this.updateToSelectedMarker(markerFound);
          this.inspectionUtilsService.currentFrameMarkerCounter = markerFound.number;
        }
      } else {
        this.inspectionUtilsService.vehicleImageEl = this.vehicleImage;
        this.damageType = '';
        if(target?.id.startsWith('marker-')==this.inspectionUtilsService.selectedMarker.id && (this.damageNoType || this.disableWalkAroundBtn))
          return;
        this.createNewMarker(event,true);
      }
    }
  }

  async updateToSelectedMarker(updatedMarker:any,isNewMarker?:boolean){
    this.damageType = updatedMarker?.damageType;
    if(!updatedMarker) return;
    this.inspectionUtilsService.selectedMarker = updatedMarker;
    if(!isNewMarker && !this.inspectionUtilsService?.selectedMarker?.imageSrc){
      this.inspectionUtilsService.selectedMarker.imageSrc = await this.inspectionUtilsService.getImageById(this.inspectionUtilsService.selectedMarker?.imageId);
      this.checkPhotoNDamageStatus();
    }
    if(this.selectedDamage != 'standardPhoto'){
      this.updateMarkerColor(this.getCurrDamageMarkers(),this.inspectionUtilsService.selectedMarker);
    }else{
      this.updateMarkerColor(this.getCurrStandardMarkers(),this.inspectionUtilsService.selectedMarker);
    }
    this.checkPhotoNDamageStatus();
  }

  updateMarkerColor(markersList?:any, selectedMarker?:any){
    markersList?.forEach((marker:any)=>{
      if(selectedMarker?.id ? selectedMarker.id != marker.id : (marker.damageType != null || marker.isStandard)){
        marker.color = this.selectedDamage == 'standardPhoto' ? '#52911f' : 'red';
      }else{
        marker.color = this.selectedDamage == 'standardPhoto' ? '#76ed17' : '#002084';
      }
    })
  }

  createNewMarker(event?:any,isNewMarker?:boolean){
    if (this.selectedDamage == 'standardPhoto') {
      this.inspectionUtilsService.addStandardMarker(this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId);
      this.updateToSelectedMarker(this.inspectionUtilsService.selectedMarker,isNewMarker);
    }else{
      const allMarkersHaveDamageType = this.getCurrDamageMarkers()?.every((marker:any) => marker.damageType);
      if ((this.inspectionUtilsService.selectedMarker?.number && !this.inspectionUtilsService.selectedMarker?.damageType) || (allMarkersHaveDamageType!=true && allMarkersHaveDamageType!=undefined)) {
        //alert('Please select a damage type for all existing markers before adding a new one.');
        this.damageNoType = true;
        return;
      }
      this.inspectionUtilsService.addDamageMarker(this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId,event);
      this.updateToSelectedMarker(this.inspectionUtilsService.selectedMarker,isNewMarker);
    }
  }

  updateDamageType(damageType:any){
    this.inspectionUtilsService.resetDamageTypes();
    let isdamageType = 'is'+damageType[0].toUpperCase() + damageType.slice(1);
    this.inspectionUtilsService.selectedMarker[`${isdamageType}`] = true;
    this.inspectionUtilsService.selectedMarker.damageType = damageType;
    this.damageNoType = false;
    this.checkForNoDamageType();
  }

  checkForNoDamageType() {
    this.disableStandardPhoto = this.getCurrDamageMarkers()?.some((marker:any) => marker.damageType == '');
  }

  nextDamage(){
    let markerCounter = this.inspectionUtilsService.currentFrameMarkerCounter;
    let maxLen = this.selectedDamage == 'standardPhoto' ? this.getCurrStandardMarkers().length : this.getCurrDamageMarkers().length;
    if(markerCounter < maxLen){
      this.inspectionUtilsService.currentFrameMarkerCounter++;
      markerCounter++;
      const nextMarker = this.selectedDamage == 'standardPhoto' ? this.getCurrStandardMarkers()[markerCounter-1] : this.getCurrDamageMarkers()[markerCounter-1];
      this.updateToSelectedMarker(nextMarker);
    }
  }

  previousDamage(){
    let markerCounter = this.inspectionUtilsService.currentFrameMarkerCounter;
    if(markerCounter>1){
      markerCounter--;
      const prevMarker = this.selectedDamage == 'standardPhoto' ? this.getCurrStandardMarkers()[markerCounter-1] : this.getCurrDamageMarkers()[markerCounter-1];
      this.inspectionUtilsService.currentFrameMarkerCounter--;
      this.updateToSelectedMarker(prevMarker);
    }
  }

  handleMajorDamage(){
    this.inspectionUtilsService.selectedMarker.isMajor = this.majorDamage;
  }

  getCurrDamageMarkers(){
    return this.inspectionUtilsService.getDamageMarkers()[this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId] || [];
  }

  getCurrStandardMarkers(){
    return this.inspectionUtilsService.getStandardMarkers()[this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId] || [];
  }

  capturedPhoto(event:any, isNewMarker?:boolean){
    if(this.selectedDamage == 'standardPhoto' && !this.updateImgSrc){
      this.createNewMarker(event,true);
    }
    this.inspectionUtilsService.selectedMarker.imageSrc = event.image;
    this.inspectionUtilsService.reValidateCardDetails(this.commonService.getFinalReportData().inspectionType);
    if(this.selectedDamage == 'standardPhoto') this.reportTypes('damagePhoto');
  }

 resetActiveCard() {
   this.vehicleWireframeData.forEach((data: any) => data.active = false);
   this.updateImgSrc = false;
   this.noStandardImage = false;
 }

 onWalkAroundDone(){
  this.noStandardImage = false;
  this.commonService.getFinalReportData().inspectionType=='OUT' ? this.checkForStandardImages() : this.checkForStandardCheckinImages();
  this.inspectionUtilsService.handleUploadingImages();
  if(this.noStandardImage) return;
  let allDamages:any = [];
  let secondaryAllDamages:any = [];
  this.inspectionUtilsService.vehicleData.wireframeGroup.forEach((item:any)=>{
    if(this.commonService.getFinalReportData().secondaryUnitNumber == undefined){
      if(this.inspectionUtilsService.getStandardMarkers()[item.wireframeId]){
        allDamages.push(...this.inspectionUtilsService.getStandardMarkers()[item.wireframeId])
      }
      if(this.inspectionUtilsService.getDamageMarkers()[item.wireframeId]){
        allDamages.push(...this.inspectionUtilsService.getDamageMarkers()[item.wireframeId])
      }
      this.commonService.getFinalReportData().damageList[this.commonService.getFinalReportData().unitNumber] = allDamages;
    }else{
      let index =this.commonService.towingUnitData.wireframeGroup.find((data: any) => data.wireframeId == item.wireframeId);
      if(this.inspectionUtilsService.getStandardMarkers()[item.wireframeId] && index){
       secondaryAllDamages.push(...this.inspectionUtilsService.getStandardMarkers()[item.wireframeId])
      }else{ allDamages.push(...this.inspectionUtilsService.getStandardMarkers()[item.wireframeId])}
      if(this.inspectionUtilsService.getDamageMarkers()[item.wireframeId] && index){
        secondaryAllDamages.push(...this.inspectionUtilsService.getDamageMarkers()[item.wireframeId])
      }else if(this.inspectionUtilsService.getDamageMarkers()[item.wireframeId]){ 
        allDamages.push(...this.inspectionUtilsService.getDamageMarkers()[item.wireframeId])}

      this.commonService.getFinalReportData().damageList[this.commonService.getFinalReportData().unitNumber] = allDamages;
      this.commonService.getFinalReportData().damageList[this.commonService.getTowingUnitData().unitNumber]=secondaryAllDamages;
    }
  })
 
  this.router.navigate(['/summary']);
 }

 checkForStandardImages(){
  this.noStandardImage = Object.keys(this.inspectionUtilsService.getStandardMarkers()).length!=this.inspectionUtilsService.vehicleData.wireframeGroup.length;
 }
 
  closeAlert(visable:boolean) {
    this.noStandardImage = visable;
  }

 checkForStandardCheckinImages() {
  let newStandardMarkers: any[] = [];
  const standardMarkers = this.inspectionUtilsService.getStandardMarkers();
  Object.keys(standardMarkers).forEach((key: any) => {
    const newStandardMarker = standardMarkers[key].find((marker: any) => marker.isNewDamage);
    if (newStandardMarker) {
      newStandardMarkers.push(newStandardMarker);
    }
  });
  this.noStandardImage =  newStandardMarkers.length != this.inspectionUtilsService.vehicleData.wireframeGroup.length;
}

 onDetailsNextCard(){
  const vehicleWireframeDataIndex = this.vehicleWireframeData.findIndex((data:any)=>data.number == this.inspectionUtilsService.selectedInspectionCardDetails.number);
  if(this.vehicleWireframeData.length-1 > vehicleWireframeDataIndex ){
   this.inspectionUtilsService.selectedInspectionCardDetails = this.vehicleWireframeData[vehicleWireframeDataIndex+1];

   // this.inspectionUtilsService.selectedInspectionCardDetails.active = false;
    this.onCardClick(this.vehicleWireframeData[vehicleWireframeDataIndex+1]);
    if(this.vehicleWireframeData[vehicleWireframeDataIndex+1].number%3 == 1){
      this.currentPage = (Math.floor( this.vehicleWireframeData[vehicleWireframeDataIndex+1].number/ 3));
    }
  }else{
    this.onCardClick(this.vehicleWireframeData[0]);
    this.currentPage = 0
  }

 }
 onDetailsBeforeCard(){
  const vehicleWireframeDataIndex = this.vehicleWireframeData.findIndex((data:any)=>data.number == this.inspectionUtilsService.selectedInspectionCardDetails.number);
  if( vehicleWireframeDataIndex !==0 ){
    this.onCardClick(this.vehicleWireframeData[vehicleWireframeDataIndex-1]);
    if(this.vehicleWireframeData[vehicleWireframeDataIndex-1].number%3 == 0){
      this.currentPage = (Math.floor( this.vehicleWireframeData[vehicleWireframeDataIndex-1].number/ 3))-1;
    }
  }else{
    this.onCardClick(this.vehicleWireframeData[this.vehicleWireframeData.length-1]);
    if(this.vehicleWireframeData[this.vehicleWireframeData.length-1].number%3 == 0){
      this.currentPage = (Math.floor( this.vehicleWireframeData[vehicleWireframeDataIndex-1].number/ 3))-1;
    }else{
      this.currentPage = (Math.floor( this.vehicleWireframeData[this.vehicleWireframeData.length-1].number/ 3));
    }
  }

 }

   removeDamage() {
      if (this.selectedDamage == 'standardPhoto') {
        const stdMarkers = this.inspectionUtilsService.getStandardMarkers()[this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId];
        let index = stdMarkers.findIndex((data: any) => data.number == this.inspectionUtilsService.selectedMarker.number);
        stdMarkers.splice(index, 1);
        this.updateToSelectedMarker(stdMarkers[0]);
        if (stdMarkers.length == 0) {
          this.inspectionUtilsService.selectedMarker = {};
        }
      }else{
        const dmgMarkers = this.inspectionUtilsService.getDamageMarkers()[this.inspectionUtilsService.selectedInspectionCardDetails.wireframeId];
        let index = dmgMarkers.findIndex((data: any) => data.number == this.inspectionUtilsService.selectedMarker.number);
        dmgMarkers.splice(index, 1);
        this.updateToSelectedMarker(dmgMarkers[0]);
        if (dmgMarkers.length == 0) {
          this.inspectionUtilsService.selectedMarker = {};
        }
      }
      this.inspectionUtilsService.reValidateCardDetails(this.commonService.getFinalReportData().inspectionType);
    }


  onDeleteInspImg(image:any){}

  onEditInspImg(){
    this.updateImgSrc = true;
    this.inspectionUtilsService.selectedMarker.imageId = null;
  }

  onWireFrameLoad(event:any){
    let index = this.vehicleWireframeData.findIndex((data: any) => data.wireframeId == event.wireframeId);
    this.vehicleWireframeData[index].svgData = event.svg;
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    this.checkPhotoNDamageStatus();
  }

  checkPhotoNDamageStatus(){
    if(this.inspectionUtilsService.selectedMarker.id && !this.inspectionUtilsService.selectedMarker.damageType && this.selectedDamage!='standardPhoto'){
      this.damageNoType = true;
    }else{
      this.damageNoType = false;
    }
    if(this.inspectionUtilsService.selectedMarker.id && this.inspectionUtilsService.selectedMarker.damageType && !this.inspectionUtilsService.selectedMarker.imageSrc && this.selectedDamage!='standardPhoto'){
      this.disableWalkAroundBtn = true;
    }else{
      this.disableWalkAroundBtn = false;
    }
  }

  toggleComment() {
    this.showComment = !this.showComment;
  }

}
