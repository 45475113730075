<div class="row">
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <img src="assets/404Error.svg" class="me-2 error404Img">
    </div>
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <h6>UH-OH!</h6>
        <span>We couldn't find the destination you were looking for.</span><br>
        <span>Possible Reasons:</span>
        <ul>
            <li>The page has moved or no longer exists.</li>
            <li>A typo in the URL -  double-check just in case.</li>
            <li>A bumpy road on our server side.</li>
        </ul>
        <img src="assets/truckWireFrame.svg" class="me-2 truckImg"><br>
        <span>Navigation Options.</span>
        <ul>
            <li>Click the Back button</li>
            <li>Return Home</li>
            <li>Navigate our site map</li>
        </ul>
    </div>
</div>