<div class="row">
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <img src="assets/401Error.svg" class="me-2 error401Img">
    </div>
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <h6>AUTHORIZED PERSONNEL ONLY</h6>
        <span>Sorry, but your're not cleared to enter this area.</span>
        <span>Possible Reasons:</span>
        <ul>
            <li>You're trying to access a page that requires login crendentials.</li>
            <li>You may need to sign in with your authorized account.</li>
            <li>Your session might have timed out.</li>
        </ul>
        <img src="assets/truckWireFrame.svg" class="me-2 truckImg"><br>
        <span>Navigation Options.</span>
        <ul>
            <li>Click the Back button</li>
            <li>Return Home</li>
            <li>Navigate our site map</li>
        </ul><br>
        <span>Friendly Reminder: Only authorized personnel can access this page. Be sure your login info is correct, or contact support if you're still having trouble.</span>
    </div>
</div>