<div class="row">
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <img src="assets/403Error.svg" class="me-2 error403Img">
    </div>
    <div class="col-lg-6 col-xl-6 col-sm-12 col-xs-12">
        <h6>SORRY! THIS ROAD IS OFF-LIMITS!</h6>
        <span>You dont't have permission to enter this area.</span><br>
        <span>Possible Reasons:</span>
        <ul>
            <li>The page is restricted to authorized users only.</li>
            <li>You may need additional permissions to proceed.</li>
            <li>Check if you're logged in with the right credentials.</li>
        </ul>
        <img src="assets/truckWireFrame.svg" class="me-2 truckImg"><br>
        <span>Navigation Options.</span>
        <ul>
            <li>Click the Back button</li>
            <li>Return Home</li>
            <li>Navigate our site map</li>
        </ul><br>
        <span>Just like a secure lot, some parts of this site are protected. Reach out if you need help accessing this area!</span>
    </div>
</div>