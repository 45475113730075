import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UnitRemarketingStatus} from "../../interfaces/unit-remarketing-status.model";
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-remarketing-alerts',
  standalone: true,
  imports:[CommonModule, DialogModule],
  templateUrl: './remarketing-alerts.component.html',
  styleUrls: ['./remarketing-alerts.component.scss']
})
export class RemarketingAlertsComponent {
  @Input() remarketingStatus: UnitRemarketingStatus = {
      unitNumber: '',
      unitHasImages: false,
      isUnitSalePending: false,
      isUnitListedToSell: false,
      havePicturesBeenRequested: false,
    };

  @Output() remarketingStatusChange: EventEmitter<UnitRemarketingStatus> = new EventEmitter();
  resetRemarketingStatus() {
    this.remarketingStatus = {
      unitNumber: '',
      unitHasImages: false,
      isUnitSalePending: false,
      isUnitListedToSell: false,
      havePicturesBeenRequested: false,
    };
  }
  showRemarketingAlerts(): boolean {
    const listedToSellButNoImages = this.remarketingStatus?.isUnitListedToSell && !this.remarketingStatus.unitHasImages;
    const notListedToSellButPhotosRequested = !this.remarketingStatus?.isUnitListedToSell && this.remarketingStatus?.havePicturesBeenRequested;
    const unitSalePending = this.remarketingStatus?.isUnitSalePending;
    return listedToSellButNoImages || notListedToSellButPhotosRequested || unitSalePending
  }

  showNoPicturesMessage(): boolean {
    const listedToSellButNoImages = this.remarketingStatus?.isUnitListedToSell && !this.remarketingStatus.unitHasImages;
    const notListedToSellButPhotosRequested = !this.remarketingStatus?.isUnitListedToSell && this.remarketingStatus?.havePicturesBeenRequested;
    return listedToSellButNoImages || notListedToSellButPhotosRequested;
  }
}
