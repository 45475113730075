import { Routes } from '@angular/router';
import { LocationGuard } from './route-guards/location.guard';
import { homeComponentResolver, HomeComponentResolverService } from './services/home-resolver/home-component-resolver.service';
import { CameraOverlayComponent } from './components/camera-overlay/camera-overlay.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { ChangeLocationComponent } from './components/change-location/change-location.component';
import { InspectionSummaryComponent } from './components/inspection-summary/inspection-summary.component';
import { HomeComponent } from './components/home/home.component';
import { InspectionHistoryComponent } from './components/inspection-history/inspection-history.component';
import { InpectionDetailsNewComponent } from './components/inpection-details-new/inpection-details-new.component';
import { SignatureComponent } from './components/signature/signature.component';
import { Error401Component } from './error/error401/error401.component';
import { Error403Component } from './error/error403/error403.component';
import { Error404Component } from './error/error404/error404.component';

export const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [LocationGuard], resolve: {remarketingStatus: homeComponentResolver}},
    { path: 'history/:id', component: InspectionHistoryComponent, canActivate: [LocationGuard] },
    { path: 'camera-overlay', component: CameraOverlayComponent, canActivate: [LocationGuard] },
    { path: 'agreement', component: AgreementComponent, canActivate: [LocationGuard] },
    { path: 'signature', component: SignatureComponent, canActivate: [LocationGuard] },
    { path: 'inspection-details-new', component: InpectionDetailsNewComponent, canActivate: [LocationGuard] },
    { path: 'location', component: ChangeLocationComponent },
    { path: 'summary', component: InspectionSummaryComponent, canActivate: [LocationGuard]},
    { path: 'error401', component: Error401Component, canActivate: [LocationGuard]},
    { path: 'error403', component: Error403Component, canActivate: [LocationGuard]},
    { path: 'error404', component: Error404Component, canActivate: [LocationGuard]},
    { path: '**', component: HomeComponent, canActivate: [LocationGuard], resolve: {remarketingStatus: HomeComponentResolverService}},
  ];
