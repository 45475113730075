import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-error401',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error401.component.html',
  styleUrl: './error401.component.scss'
})
export class Error401Component {

}
