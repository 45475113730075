<div class="agreement mx-auto">
    <div class="agreement-container p-md-4 mt-md-3">
        <div class="agreement-header p-4 text-left">
            <h2 class="blue-color">Rental Inspection Report</h2>
            <span>{{isUserSignedAndAccepted ? 'Signed on' : 'Completed on'}} {{commonService.getDateFormatted()}}</span>
        </div>
        <div class="agreemnet_pdf">
            <pdf-viewer
                [src]="pdfLink"
                [rotation]="0"
                [original-size]="false"
                [show-all]="isUserSignedAndAccepted?true:false"
                [page]="page"
                [fit-to-page]="false"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                (after-load-complete)="afterLoadComplete($event)"
                style="width: 100%; height: 50vh;"
          ></pdf-viewer>
        </div>
        <div class="agreement-footer p-4 shadow">
            <p class="text-center mb-1 fs-14px" *ngIf="isUserSignedAndAccepted">Thank you for choosing Penske.</p>
            <h6 class="blue-color mb-4">{{isUserSignedAndAccepted ? 'Final Summary and Agreement' : 'Customer Agreement'}}</h6>
            <div class="agreement_btns d-flex justify-content-between">
                <button *ngIf="!isInspectionDone" class="btn fw-medium fs-14px" (click)="onEdit()">Edit Report</button>
                <button *ngIf="!isUserSignedAndAccepted" class="btn fw-medium fs-14px" [disabled]="page==1" (click)="prevPage()">Previous Page</button>
                <button class="btn fw-medium fs-14px" *ngIf="totalPages!=page && !isUserSignedAndAccepted" [disabled]="totalPages==page" (click)="nextPage()">Next Page</button>
                <button class="btn btn-yellow fw-medium border-0 fs-14px" *ngIf="totalPages==page || isUserSignedAndAccepted" (click)="onAgree()">{{isUserSignedAndAccepted ? (commonService.finalReportData.sendEmail? "Accept and Email" :"Accept and Print"): 'I Agree'}}</button>
            </div>
        </div>
    </div>
</div>

<app-loader *ngIf="showLoader"></app-loader>
