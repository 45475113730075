import {Component, Input, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import { LoaderComponent } from '../loader/loader.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/services/common/common.service';
import { PdfService } from 'src/app/services/pdf/pdf.service';
import { UnitRemarketingCheck } from 'src/app/models/unit-remarketing-check';
import { REMARKETING_CHECK } from 'src/app/constants/app-constants';

@Component({
  selector: 'app-agreement',
  standalone: true,
  imports: [LoaderComponent, PdfViewerModule, CommonModule],
  templateUrl: './agreement.component.html',
  styleUrl: './agreement.component.scss'
})
export class AgreementComponent implements OnInit {
  public showLoader: boolean = false;
  public pdfLink: string = '';
  public inspectionId: string = '';
  public page: number = 1;
  public totalPages: number = 0;
  public isLoaded = false;
  @Input() isUserSignedAndAccepted: boolean = false;
  public isInspectionDone: boolean = false;
  public inspectionComplete: boolean = false;
  constructor(private router: Router, public commonService: CommonService,private pdfService: PdfService) {
  }

  ngOnInit(): void {
    this.inspectionId = this.commonService.pdfData.id;
    this.pdfLink = this.commonService.pdfData.link;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  onEdit() {
    this.router.navigate(['/summary']);
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  onAgree() {
    const unitNumber = this.commonService.finalReportData?.unitNumber;
    if (!this.isUserSignedAndAccepted) {
      this.router.navigate(['/signature']);
    } else {
      this.isInspectionDone = true;
      window.open(this.commonService.pdfData.link);
      this.inspectionComplete = true;
      this.commonService.isInspectionComplete = true;
      const remarketingStatus : UnitRemarketingCheck = new UnitRemarketingCheck();
      remarketingStatus.performRemarketingCheck = true;
      remarketingStatus.unitNumber = unitNumber;
      const extras: NavigationExtras = {state: {[REMARKETING_CHECK] : remarketingStatus}};
      this.router.navigate(['home'], extras);
      if(this.commonService.finalReportData.sendEmail){
        const payload:any = {
          "reportMasterId": this.commonService.pdfData.id,    
           "sendEmail": this.commonService.finalReportData.sendEmail,    
            "emailAddress":this.commonService.finalReportData.emailAddress,     
            "ccEmailAddress":this.commonService.finalReportData.ccEmailAddress
        }
        this.pdfService.sendEmail(payload).subscribe({
          next:(res:any)=>
            {console.log(res)},
          error:(e)=>{
            console.log('Error occured ');
          }});
        }
    }
  }
}
