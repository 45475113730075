import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-change-location',
  standalone: true,
  imports:[CommonModule, FormsModule, FloatLabelModule, InputTextModule],
  templateUrl: './change-location.component.html',
  styleUrls: ['./change-location.component.scss']
})
export class ChangeLocationComponent implements AfterViewInit {
  deviceLocationId: string = '';
  isInvalid: boolean = false;
  hasInteracted: boolean = false;
  @ViewChild('locationInput') locationInputEl!:ElementRef

  constructor(private router: Router, private commonService: CommonService) {}

  ngOnInit() {
      this.deviceLocationId = this.commonService.getDeviceLocationId() || '';
      this.validatePattern();
  }

  ngAfterViewInit(): void {
    if(this.deviceLocationId){
      this.locationInputEl.nativeElement.focus();
    }
  }


  onLocationEnter() {
    // Attach the location code to the global object.
    // Also need to store the location into the cookie or local storage as needed
    this.commonService.setDeviceLocationId(this.deviceLocationId);
    this.router.navigate(['/home']);
  }

  validatePattern() {
    const pattern = /^\d{4}-\d{2}$/;
    this.isInvalid = !pattern.test(this.deviceLocationId);
  }

  onInputChange() {
    this.hasInteracted = true;
    this.validatePattern();
  }

  onCancel(){
    this.router.navigate(['home']);
  }
}
