import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, from, Observable, switchMap, throwError } from "rxjs";
import { KeycloakService } from "keycloak-angular";

@Injectable()
export class LocalAuthInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  // Function to clone the request and add the Authorization header
  private cloneRequestWithToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: { Authorization: `Bearer ${token}` }
    });
  }

  // Function to handle token retrieval (refreshing if expired)
  private handleRequestWithToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const clonedRequest = this.cloneRequestWithToken(req, token);
        return next.handle(clonedRequest);
      }),
      catchError((error) => {
        console.error("Error while retrieving token:", error);
        // Fallback with a hardcoded ssoid if the token retrieval fails
        const ssoid = '600555555'; // Replace with actual fallback token or user ID
        const clonedRequest = this.cloneRequestWithToken(req, ssoid);
        return throwError(clonedRequest);
      })
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the token is expired, update it first
    if (this.keycloakService.isTokenExpired()) {
      return from(this.keycloakService.updateToken()).pipe(
        switchMap((refreshed) => {
          if (refreshed) {
            console.log("Token has been refreshed.");
          }
          // After refreshing, proceed to handle the request with the new token
          return this.handleRequestWithToken(req, next);
        }),
        catchError((error) => {
          console.error("Error while refreshing token:", error);
          return this.handleRequestWithToken(req, next); // In case of error, fallback to token retrieval
        })
      );
    }

    // If the token is not expired, handle the request with the existing token
    return this.handleRequestWithToken(req, next);
  }
}
